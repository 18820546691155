import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { HEADER_TEXTS } from "../assets/data/languages";

const Header = props => (
  <div className="nav-container" id="start">
    <div className="bar bar--sm visible-xs">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 text-right">
            <a
              href="#"
              className="hamburger-toggle"
              data-toggle-className="#menu1;hidden-xs"
            >
              <i className="icon icon--sm stack-interface stack-menu" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <nav id="menu1" className="bar bar--sm bar-1 hidden-xs ">
      <div className="container">
        <div className="row">
          <div className="col-lg-11 col-md-12 text-right text-left-xs text-left-sm">
            <div className="bar__module">
              <ul className="menu-horizontal text-left">
                <li className="dropdown">
                  <a href="#entities" className="scroll-link">
                    <span className="nav-item-custom">Entities</span>
                  </a>
                </li>
                <li className="dropdown">
                  <a href="#stack" className="scroll-link">
                    <span className="nav-item-custom">Tech Stack</span>
                  </a>
                </li>
                <li className="dropdown">
                  <a href="#demo" className="scroll-link">
                    <span className="nav-item-custom">Demo</span>
                  </a>
                </li>
                <li className="dropdown">
                  <a href="#participate" className="scroll-link">
                    <span className="nav-item-custom">Contribute</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="bar__module">
              <a
                className="btn btn--primary type--uppercase"
                href="mailto:info@universis.gr?Subject=Πληροφορίες για UniverSIS"
              >
                <span className="btn__text">{HEADER_TEXTS.contact.en}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
