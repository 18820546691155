export const HERO_TEXTS = {
  subtitle: {
    en: "Redesigning our universities universe.",
    gr: "Σχεδιάζοντας τον κόσμο των Ελληνικών Ακαδημαϊκών Ιδρυμάτων."
  },
  info: {
    en:
      "An open source Student Information System to manage your academic processes, supported by a collaborative community.",
    gr:
      "Ένα πληροφοριακό σύστημα Ηλεκτρονικής Γραμματείας ανοιχτού κώδικα, για τη διαχείριση των ακαδημαϊκών διαδικασιών, με την υποστήριξη της συνεργατικής κοινότητας των Πανεπιστημίων."
  },
  button: {
    en: "Learn More",
    gr: "Μάθε Περισσότερα"
  }
};

export const HEADER_TEXTS = {
  action: {
    en: "ACTION",
    gr: "ΔΡΑΣΗ"
  },
  chars: {
    en: "FEATURES",
    gr: "ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ"
  },
  trial: {
    en: "TRY IT",
    gr: "ΔΟΚΙΜΗ"
  },
  contibute: {
    en: "CONTRIBUTE",
    gr: "ΣΥΜΜΕΤΟΧΗ"
  },
  language: {
    en: "GR",
    gr: "EN"
  },
  contact: {
    en: "Contact",
    gr: "Επικοινωνία"
  }
};

export const ACTION_TEXTS = {
  title: {
    en: "Action",
    gr: "Δράση"
  },
  subtitle: {
    en: "We aim to build a great student information system, as a community.",
    gr: "Δημιουργούμε ως κοινότητα ένα νέο σύστημα ηλεκτρονικής γραμματείας."
  },
  open: {
    en: "Open Source",
    gr: "Ανοιχτού κώδικα"
  },
  openSub: {
    en:
      "Built from the ground up as an open source platform where academic institutions and commercial partners can contribute and benefit equally.",
    gr:
      "Χτισμένο εκ βάθρων ως πλατφόρμα ανοιχτού λογισμικού όπου ακαδημαϊκά ιδρύματα και εμπορικοί εταίροι μπορούν να συνεισφέρουν και να ωφεληθούν ταυτόχρονα."
  },
  synergy: {
    en: "Collaborative",
    gr: "Συνεργατικό"
  },
  synergySub: {
    en:
      "Based on the collaboration between academic institutions tuned to meet the real needs of a demanding community.",
    gr:
      "Βασισμένο στην συνεργασία μεταξύ ακαδημαϊκών ιδρυμάτων και σχεδιασμένο να ικανοποιεί τις πραγματικές ανάγκες μίας απαιτητικής κοινότητας χρηστών."
  },
  modern: {
    en: "State-of-art",
    gr: "Σύγχρονο"
  },
  modernSub: {
    en:
      "Built on modern technologies to ensure performance under load and future extensibility.",
    gr:
      "Κατασκευασμένο πάνω σε σύγχρονες τεχνολογίες που εγγυώνται ταχύτητα και ασφάλεια σε συνθήκες μεγάλης κίνησης και ευελιξία για μελλοντική επεκτασιμότητα."
  }
};

export const TIMELINE_TEXTS = {
  title: {
    en: "Timeline",
    gr: "Χρονοδιάγραμμα"
  },
  subtitle: {
    en: "We build the platform with you.",
    gr: "Χτίζουμε την πλατφόρμα με εσάς."
  },
  date1: {
    en: "June 2018",
    gr: "Ιούνιος 2018"
  },
  date1text: {
    en:
      "The project starts, with the participation of AUTH and DUTH design and development teams.",
    gr:
      "Εκκίνηση του έργου με την συμμετοχή ομάδων σχεδίασης και απάτυξης του Α.Π.Θ. και του Δ.Π.Θ"
  },
  date2: {
    en: "December 2018",
    gr: "Δεκέμβρης 2018"
  },
  date2text: {
    en:
      "First versions of Students and Teachers sub-systems, get published. Start of design and development phases, of the Registrar sub-system.",
    gr:
      "Δημοσίευση της πρώτης έκδοσης των συστημάτων Φοιτητή και Καθηγητή, εκκίνηση ανάπτυξη συστήματος Γραμματείας"
  },
  date3: {
    en: "June 2019",
    gr: "Ιούνιος 2019"
  },
  date3text: {
    en:
      "Completion of the first project cycle, with the publishment of the final versions of Students, Teachers, and Registrar sub-systems. Start of the second project phase.",
    gr:
      "Κλείσιμο 1ου κύκλου με δημοσίευση των τελικών εκδόσεων συστημάτων Φοιτητή, Καθηγητή και Γραμματείας. Εκκίνηση νέου κύκλου ανάπτυξης."
  }
};

export const CONTACT_TEXTS = {
  title: {
    en: "Contact us",
    gr: "Επικοινωνήστε μαζί μας"
  },
  subtitle: {
    en:
      "Let's discuss how UniverSIS fits in your current workflow, how you can try it live, explore its capabilities, and adapt it to your needs.",
    gr:
      "Ας συζητήσουμε πως το UniverSIS μπορεί να ταιριάξει στη σημερινό τοπίο των υπηρεσιών σας, πώς να δοκιμάσετε ζωντανά όλες τις λειτουργίες του και να το προσαρμόσετε στις ανάγκες σας."
  },
  subtitle2: {
    en: "Contact us, we will be more than happy to talk.",
    gr: "Επικοινωνήστε μαζί μας και θα έρθουμε σε επαφή άμεσα μαζί σας."
  }
};

export const CHARACTERISTICS_TEXTS = {
  title: {
    en: "A setup built for you",
    gr: "Ένα ειδικά σχεδιασμένο περιβάλλον"
  },
  subtitle: {
    en:
      "Enhance your academic and administrative management with easy to use, and powerful features",
    gr:
      "Για τη βελτίωση της ακαδημαϊκής και διοικητικής διαχείρισης της Γραμματείας Τμήματος, με εύχρηστες και ευέλικτες λειτουργίες"
  },
  design: {
    en: "Optimized User Experience",
    gr: "Επαγγελματική σχεδίαση εμπειρίας χρήστη"
  },
  designSub: {
    en:
      "Adopting a user-centered approach, design based on user experience, research and evaluation",
    gr:
      "Σχεδιασμένο με επίκεντρο τον τελικό χρήστη, βασισμένο σε έρευνα και αξιολόγηση της εμπειρίας χρήσης φοιτητών, διδασκόντων και γραμματειών, ώστε να ικανοποιεί τις πραγματικές ανάγκες τους"
  },
  agnostic: {
    en: "Platform Agnostic",
    gr: "Διαλειτουργικότητα"
  },
  agnosticSub: {
    en:
      "Following modern methods to produce a cross-device, cross-platform interface",
    gr:
      "Τελική διεπαφή που ακολουθεί σύγχρονες μεθόδους για να λειτουργεί σε όλες τις συσκευές και όλα τα περιβάλλοντα, web και mobile"
  },
  flexible: {
    en: "Flexible Adoption",
    gr: "Ευέλικτη εγκατάσταση"
  },
  flexibleSub: {
    en:
      "Enabling integration with existing legacy systems and migration of components at your own pace",
    gr:
      "Επιτρέπει τη λειτουργική ένταξη στις υπάρχουσες υποδομές και υπηρεσίες των ιδρυμάτων, υποστηρίζοντας παράλληλη λειτουργία με παλαιά συστήματα και σταδιακή μετάβαση"
  },
  community: {
    en: "Engaged Community",
    gr: "Με ενεργή κοινότητα"
  },
  communitySub: {
    en:
      "Enabling integration with existing legacy systems and migration of components at your own pace",
    gr:
      "Βασισμένο σε αρχές συμμετοχικότητας από όλους, φιλοδοξεί στη συνεργασία όλων των ιδρυμάτων για να αυξήσει την αποδοτικότητα της λύσης"
  },
  secure: {
    en: "Secure",
    gr: "Ασφαλές"
  },
  secureSub: {
    en: "Translation needs to be added",
    gr:
      "Σχεδιασμένο με γνώμονα την ασφάλεια τόσο στην καθημερινή χρήση όσο και στη μακροχρόνια διατήρηση των δεδομένων με δυνατότητες ιχνηλάτησης και επικύρωσης των δεδομένων"
  },
  architecture: {
    en: "Powerful Performance",
    gr: "Σύγχρονη αρχιτεκτονική"
  },
  architectureSub: {
    en: "Translation needs to be added",
    gr:
      "Σχεδιασμένο για επεκτασιμότητα και κλιμάκωση στη λειτουργία επιτυγχάνοντας γρήγορη απόκριση σε απαιτητικά σενάρια φόρτου, με τη βέλτιστη χρήση πόρων"
  }
};

export const TRIAL_TEXTS = {
  title: {
    en: "Easy Start. Easy Setup",
    gr: "Εύκολη εκκίνηση, εύκολη εγκατάσταση"
  },
  subtitle1: {
    en: "Integrate UniverSIS with your existing environment.",
    gr:
      "Συνδέστε το UniverSIS με το υπάρχον σύστημά σας, χωρίς να το αποδεσμεύσετε."
  },
  subtitle2: {
    en:
      "No need to abandon your legacy system, you can try UniverSIS side-by-side with your current SIS system.",
    gr:
      "Χρησιμοποιήστε και τα δύο ταυτόχρονα, αποκομίζοντας όλα τα οφέλη από το νέο με την παράλληλη λειτουργία του παλιού, μέχρι να εξοικειωθείτε με όλες τις νέες λειτουργικότητες και να αποφασίσετε την μετάβαση."
  },
  student: {
    en: "Student System",
    gr: "Σύστημα Φοιτητών"
  },
  professor: {
    en: "Professor System",
    gr: "Σύστημα Καθηγητών"
  },
  secretary: {
    en: "Secretary System",
    gr: "Σύστημα Γραμματείας"
  },
  //   Call to Join Texts
  joinTitle: {
    en: "Contribute. Be part of the team",
    gr: "Συμμετοχή. Γίνετε μέλος της ομάδας"
  },
  joinSubtitle: {
    en:
      "We know contributing to a big project is intimidating at first, but we have created the right environment to get you easily on-board.",
    gr:
      "Η συμμετοχή σε ένα μεγάλο project αποφέρει πολλαπλά οφέλη καθώς δημιουργούμε τα θεμέλια για ένα περιβάλλον που θα υποστηρίξει την ένταξη και νέων συνεργατών με τον πιο εύκολο τρόπο."
  },
  dev: {
    en: "Are you a developer?",
    gr: "Είσαι developer?"
  },
  button1: {
    en: "Join on GITLAB",
    gr: "ΓΙΝΕΤΕ ΜΕΛΟΣ ΤΟΥ project ΣΤΟ GITLAB"
  },
  button2: {
    en: "Visit our website",
    gr: "Επισκεψου την σελίδα μας"
  }
};

export const UNIVERSITIES_TEXTS = {
  title: {
    en: "By institutions. For institutions.",
    gr: "Από τα Ιδρύματα για τα Ιδρύματα."
  },
  subtitle: {
    en:
      "Brought to the academic community with the collaboration and support of the following institutes.",
    gr:
      "Η λύση προσφέρεται από την ίδια την ακαδημαϊκή κοινότητα με την συνεργασία και υποστήριξη των παρακάτω ιδρυμάτων."
  },
  auth: {
    en: "Aristotle University of Thessaloniki",
    gr: "Αριστοτέλειο Πανεπιστήμιο Θεσσαλονίκης"
  },
  duth: {
    en: "Democritus University of Thrace",
    gr: "Δημοκρίτειο Πανεπιστήμιο Θράκης"
  },
  cta: {
    en: "Be part of the action",
    gr: "Γινετε μελος της δρασης"
  }
};
